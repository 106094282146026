<template >
    <div id="EditPrm">
          
               <div class="form-content">
            <form @submit.prevent="
                submit(
                  added.name , added.abr, added.uni, added.lc, added.ref,added.type, added.acreditacion, added.incertidumbre
                )
              ">
                    <div class="text_option txt1">
                           
                            <h3>Información del Usuario</h3>
                    </div>

                     <div class="grup-form">
                        <div>
                            <label for="name" class="name">Nombre del parámetro</label>
                            <input  v-model="added.name" type="text" id="name" name="name"   required class="in2">
                        </div>
                    </div>

                    <div class="grup-form">
                        <div>
                            <label for="uni" class="uni">Unidades</label>
                            <input v-model="added.uni" type="text" id="uni" name="uni"   required class="in3" placeholder="u1,u2,u3">
                        </div>
                    </div>

                   

                    <div class="grup-form dflx">
                        <div>
                            <label for="abr" class="abr">Abreviatura</label>
                            <input v-model="added.abr" type="text" id="abr" name="abr"   required class="in1">
                        </div>
                         
                        <div>
                            <label for="lc" class="lc">Limite de Cuantificación</label>
                            <input v-model="added.lc" type="text" id="lc" name="lc" required class="in1" placeholder="0,0">
                        </div>
                    </div>

                     <div class="grup-form">
                        <div>
                            <label for="ref" class="ref">Referencia</label>
                            <input  v-model="added.ref" type="text" id="ref" name="ref"   required class="in3">
                        </div>
                    </div>

                    <p class="parm">*Separar por <strong>coma (, )</strong> las unidades y el limite de cuantificación.</p>

                    <div  class="grup-form">
                        <div>
                            <label for="role" class="role">Tipo </label>
                                <select id="select-role" v-model="added.type" required name="role">
                            <option >
                                1
                            </option>
                                
                                <option v-if="identity.role=='spadmin'">
                                2
                                </option>

                            </select>
                        </div>
                    </div>

                     

                    <div class="grup-form">
                        <div>
                            <label for="acreditacion">Acreditación</label>
                            <input  v-model="added.acreditacion" type="text" id="acreditacion" name="acreditacion" required class="in3">
                        </div>
                    </div>

                    <div class="grup-form">
                        <div>
                            <label for="incertidumbre">Incertidumbre</label>
                            <input  v-model="added.incertidumbre" type="text" id="incertidumbre" name="incertidumbre" class="in3">
                        </div>
                        
                    </div>

                            <div class="dflx">
                                 <p @click="wait()" class="btnRegresar">Regresar</p>
                                      <button class="alta" type="submit"><span>Aceptar</span></button>
                            </div>
                   

                        <div v-if="status !=''" class="status_messages">
                            <div v-if="status =='success'" class="msg msg_success">
                                 <p>{{message}}</p>
                            </div>
                            <div  v-if="status =='error'" class="msg msg_error">
                                <p>{{message}}</p>
                            </div>
                        </div>
            </form>


                     
             <div v-if="viewModal" class="modal_view">
           
            <div id="modal_edit">
            <p @click="closeModal()" class="close_modal"> &#215;</p>
            <h3 class="title_edit">Actualización <span>exitosa</span></h3>
           
            <div class="modal_edit_btns dflx">
                <p @click="Edit()" class="edit_otro">VOLVER A EDITAR</p> <p  @click="back()" class="edit_inicio">INICIO</p>
            </div>
          </div>
        </div>


        </div>
          

    </div>
</template>
<script>

import {mapActions} from 'vuex';
export default {
    name:'EditPrm',
    
    data(){
        return{
           status:'',
           message:'',
          
           pass:"",
           passc:"",

            //MODAL

            viewModal:false,
          
        }
    },
    created (){
       this.getIdentity()
    },  
    computed:{
        added(){
            return this.$store.getters["parametros/getAdded"]
        },
          identity() {
           return this.$store.getters['admin/getIdentity'];
        },
    },
    methods:{
          ...mapActions('parametros', ['setAddedPrm']),
         ...mapActions('parametros', ['setHistoryOptionPrm']),
        ...mapActions("parametros", ["getInfoByIdPrm"]),
          getIdentity: async function (){
          let response =  await this.$store.dispatch("admin/getData");
          return response
        },
         wait: function(){
            setTimeout(() => this.$router.go(), 200);
        },
             submit: async function(  name , abr, uni, lc, ref , type, acreditacion, incertidumbre){
                 this.status =''
                 this.message =''
            
              

                let parametro ={
                    "id":this.added.id,
                    "name":name,
                    "abr":abr,
                    "uni":uni,
                    "lc":lc,
                    "ref":ref,
                    "type":type,
                    acreditacion,
                    incertidumbre
                  }
                   

                        let result = await this.$store.dispatch("parametros/editItemPrm",  {option:'params', item: parametro});
                    
                        if(result.status=='error'){
                            this.status='error'
                            this.message= result.message
                        }else{ // success
                            this.showModal()
                        } 
                        
               
           
             

            
    },


      showModal: function(){
         this.viewModal=true
     },
    closeModal:function(){
        this.viewModal=false;
        this.setAddedPrm('')
        this.setHistoryOptionPrm('Default')
    },
    Edit:function(){
        this.viewModal=false;
    },
    back:function(){
       setTimeout(() => this.$router.go(), 200);
    }
    }
}
</script>
<style scoped>

     p.parm{
            color: #868686;
        font-size: 0.7291666666666666VW;
        font-weight: 400 !important;
    }



    .text_option h3{
   
    margin-top: 0vw;
    font-size: 1.8229166666666667VW;
    color: var(--color-4);
    font-weight: 300 !important;
    font-style: normal;
  }


    .form-content{
        margin-left: 0.9375VW ;
    }

  button.alta{
      width: 7.916666666666666VW;
      height: 2.2916666666666665VW;
      border: 0px;
      color: white;
      background: #00A175;
      margin-left: 0.625VW;
      margin-top: 2.7083333333333335VW;
  }
  button.alta span{
      font-weight: 600 !important;
  }
    .grup-form {
        display: flex;
    }
    .grup-form input {
        margin-top: 0vw;
    }
    .grup-form span {
        color: #868686;
        font-size: 0.7291666666666666VW;
        font-weight: 400 !important;
        writing-mode: vertical-rl;
        text-orientation: upright;
        letter-spacing: -0.5vw;
    }
  label{
    color: #868686;
    font-size: 0.7291666666666666VW;
    font-weight: 400 !important;
    width: fit-content;
    margin-right: 0.4vw;
    margin-bottom: 0vw;
  }

  input{
      border: 0.052083333333333336VW solid #D3D3D3 ;
       color: #868686;
  }

  input.in1{
      width: 10.8125VW;
  }

  input.in5{
      width: 9.812499999999998VW;
  }



    input.in2{
      width: 27.489583333333336VW;
  }

    input.in3{
      width: 31.927083333333332VW;
  }

    input.in4{
      width: 30.677083333333332VW;
  }

    label.name{
        width: 7.822917VW; 
        margin-right: 0.7291666666666666VW;
    }

    label.uni, label.ref{
        width: 3.229166666666667VW; 
        margin-right: 0.8854166666666666VW;
    }
    
   
    label.lc{
        width: 7.3875VW;
    margin-right: 1.374583VW;
    margin-left: 1.5VW;
    }

    label.abr{
        width: 3.0208333333333335VW;
        margin-right: 1.09375VW;
    }


    .txt2{
        margin-top: 2.083333333333333VW;
    }

    .txt1{
        margin-bottom: 1.6145833333333335VW;
    }



    .grup-form{
        margin-bottom: 0.6770833333333334VW;
    }


 label.role{
        width: 1.3875VW;
        margin-right: 2.7VW;
        margin-left:0vw;
    }


select{
      border: 0.052083333333333336VW solid #D3D3D3 ;
       color: #868686;
        width: 5VW;
  }

  select:focus-visible{
      outline: none !important;
  }



    
</style>
<template>
    <div id="PDFview">
        <div class="text_option txt1">
            <h3 >Informa de Prueba</h3>
        </div>
        <div class="">
            <div class="pdfv">
                <div v-if="idioma === 'english'"><PDFcreate_eng ref="savePdf" @saveFilePdf="saveFilePdf" /></div>
                <div v-else><PDFcreate ref="savePdf" @saveFilePdf="saveFilePdf" /></div>
                <div class="btnsPDF">
                    <div class="boxbtn">
                        <button @click="wait()" class="inicio">Inicio</button>
                    </div>
                    <div class="boxbtn">
                        <button @click="changeView('Resultados',added)" class="modificar">Modificar</button>
                    </div>
                    
                    <div class="boxbtn">
                        <button @click="validar(added, identity)" class="validar">Confirmar</button>
                    </div>
                </div>
            </div>
            

        </div>


          <div v-if="status !=''" class="status_messages">
                            <div v-if="status =='success'" class="msg msg_success">
                                 <p>{{message}}</p>
                            </div>
                            <div  v-if="status =='error'" class="msg msg_error">
                                <p>{{message}}</p>
                            </div>
            </div>



         <div v-if="viewModal" class="modal_view"> 
           
              <div id="modal_val">
            <p @click="closeModal()" class="close_modal"> &#215;</p>
            <h3 class="title_val"> <span>Validación</span> exitosa</h3>
           
            <div class="modal_val_btns dflx">
                 <p  @click="back()" >ACEPTAR</p>
            </div>
          </div>
        </div> 
       
       
    </div>
</template>
<script>



import {mapActions} from 'vuex';
import dayjs from 'dayjs';
import PDFcreate from '../../components/PDFcreate.vue';
import PDFcreate_eng from '../../components/PDFcreate_eng.vue';
import userService from '../../services/user.service'
import axios from "axios";

export default {
    name:'PDFview',
    data(){
        return{
            status:'',
            message:'',
            item: '',
            viewModal:false,
            texto:'',
            texdos:'',
           english_textone:'',
           english_texttwo:'',
           idioma:'',
           filePdf: null
        }
    },
    components:{
        PDFcreate,
        PDFcreate_eng
    },
    async created(){
        this.getIdentity()
        this.idioma = localStorage.getItem('language')
        
        let nose = await this.getAllInfoTxt('texto')
        
        this.texto = nose.result[0].texto
        this.texdos = nose.result[0].texdos
        this.english_textone = nose.result[0].english_textone
        this.english_texttwo = nose.result[0].english_texttwo
    },
    computed:{
        added(){
                    return this.$store.getters["validaciones/getAdded"]
        },
         identity() {
             return this.$store.getters['admin/getIdentity'];
       }
    },

    methods: {
        ...mapActions('validaciones', ['setHistoryOptionVal']),
        ...mapActions('validaciones', ['setAddedVal']),
        ...mapActions('texto', ['getAllInfoTxt']),
        ...mapActions('texto', ['addItemTxt']),
            
        getIdentity: async function (){
          let response =  await this.$store.dispatch("admin/getData");
          return response
        },
         showModal: function(){
          
             this.viewModal=true
         },
        
         closeModal:function(){
             this.viewModal=false;
             this.mtra ='';
         },
        wait: function(){
            setTimeout(() => this.$router.go(), 200);
        },
         back:function(){
        setTimeout(() => this.$router.go(), 200);
        },
        delStatus: function () {
            setTimeout(() => this.delMsgs()
            ,2000);
           /* setTimeout(() => this.wait()
            ,500); */
            
        },
        delMsgs: function(){
            this.status=""
            this.message=""
        },

         changeView(option, item){
             this.item=item
             this.setAddedVal(item)
             this.setHistoryOptionVal(option)
         },

        metodoRef() {
            
        },

        async saveFilePdf(pdfData){
            
            if(pdfData.size>0 && this.added !== undefined){
                let token = userService.getToken() 
                let  headers = {
                    Authorization: token
                }
                var data = new  FormData();
                data.append('image', pdfData);
                data.append('id_muestra', this.added.id);
                data.append('_method', 'POST');
                const result = await axios.post(`muestras/upload/file`, data, {headers});
                
                if(result.data.status) this.showModal();
                else this.showModal();
            }
        },

         validar: async function (muestra, admin){

              if(admin != undefined && muestra != undefined){
                        /** hacemos el update de la firma del analista */
                        
                    if(muestra.extra.apar == null || muestra.extra.apar ==''){
                        this.message='Faltan campos por llenar. (fecha, hora,apariencia, cantidad, presentación, transporte).'
                        this.status='error'
                        this.delStatus();
                    }else{
                        
                        let data0 ={
                            texto: this.texto,
                            texdos: this.texdos,
                            id_muestra_extra:muestra.id,
                            english_textone: this.english_textone,
                            english_texttwo: this.english_texttwo
                        }

                        let data ={
                            id:muestra.id,
                            superviso: admin.ini
                        }

                        let result0 = await this.$store.dispatch("texto/addItemTxt",  {option:'texto', item: data0});

                        let result = await this.$store.dispatch("validaciones/editItemVal",  {option:'muestras/fs', item: data});
                                               
                        let datapdf = {
                            id: muestra.id,
                            pdfidioma: 'ingles'
                        }
                        if(this.idioma !== 'english') datapdf.pdfidioma='español'
                        let resultpdf = await this.$store.dispatch("muestras/editItemMtr",  {option:'muestras/pdfsaveidiom', item: datapdf});
                    
                        if(result.status=='error' || result0.status=='error'){
                            this.status='error'
                            this.message= result.message
                            this.delStatus();
                        }else{ // success
                            this.$refs.savePdf.getPdf(true)
                            localStorage.removeItem('language')
                        }
                    }
                       
                }
           
         }
    },
}
</script>
<style scoped>
        .text_option h3{
   
    margin-top: 0vw;
    font-size: 1.8229166666666667VW;
    color: var(--color-4);
    font-weight: 300 !important;
    font-style: normal;
    margin-bottom: 2.083333333333333VW;
  }
        .txt1{
            margin-bottom: 1.6145833333333335VW;
        }



        .pdfv{
            width: 100%;
            height: fit-content;
            margin-right: 2.1354166666666665VW;
        }

         button.modificar, button.inicio{
        background:#868686 ;
        color: white;
        border: none;
        width: 10.15625VW;
        height: 2.2916666666666665VW;
        margin-top:  0vw;
        margin-bottom: 0.5729166666666666VW;
         
    }

     button.validar{
        background:#007E94 ;
        color: white;
        border: none;
        width: 10.15625VW;
        height: 2.2916666666666665VW;
        margin-top: 0vw
    }
    .btnsPDF{
        display: flex;
        margin-top: 1vw;
    }
    .boxbtn{
        width: 11vw;
    }

       
</style>